import "whatwg-fetch";


export class PostError{
    constructor (url, errors){
        this.errors = errors;
        this.url = url;
    }
}

// Function post sends the json data obj to the url url with method POST and returns the result
export default async function(url, obj){
    const resp = await fetch(url, { 
        method: "POST",
        headers: { "Content-Type": "application/json", "accept": "application/json" },
        body: JSON.stringify(obj)
    })
    if (!resp.ok) {
        let res =""
        try {
            res = await resp.text()
            const resJ =JSON.parse(res);
            if (resJ.Errors) {
                throw new PostError(url, resJ.Errors)
            }
        } catch(err) {
            // do nothing
        }
        throw new Error(`API responded with error code ${resp.status}: ${res}`)
    }
    return await resp.json();
}

