<script>
  import {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    ModalFooter,
    Alert,
  } from "sveltestrap";

  import post, {PostError} from "./post";

  let isSubmitting = false;
  let errorMessage = "";
  const apiBaseURL = document.querySelector("input#contactAPIBaseURL").value;
  const region = document.querySelector("input#bfRegion").value;
  const recaptchaSiteKey = document.querySelector(
    "input#recaptchaSiteKey"
  ).value;

  function recaptcha() {
    return new Promise((resolve, reject) =>
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptchaSiteKey, { action: "submit" })
          .then(resolve)
          .catch(reject);
      })
    );
  }

  async function onSubmit() {
    console.log("submitting with site key " + recaptchaSiteKey);
    isSubmitting = true;
    hasSubmitted = true;
    errorMessage = "";
    emailError = "";
    typeError = "";
    try {
      const obj = {
        Region: region,
        Name: name,
        UserAgent: window.navigator.userAgent,
        Expected: expected,
        Actual: actual,
        Subject: subject,
        Email: email,
        RecaptchaResponse: await recaptcha(),
        URL: window.location.href,
        ContactMe: contactMe,
        Type: type
      };
      await post(apiBaseURL + "/v2/messages/", obj);
      onSuccess();
    } catch (e) {
      if (e instanceof PostError){
          for (const er of e.errors){
            if (er.FieldName === "Email"){
              emailError = er.Message;
              errorMessage = "Please check the highlighted values";
            }
            if (er.FieldName === "Type"){
              typeError = er.Message
              errorMessage = "Please check the highlighted values";

            }
          }
      } 
      errorMessage = errorMessage || "An error occurred";
      console.error("error submitting message: ", e);
      isSubmitting = false;
      return;
    }
  }

  export let onSuccess, onClose;

  let actual = "",
    expected = "",
    name = "",
    subject = "",
    email = "";

  function valid(val, max, required) {
    if (required && !val) {
      return false;
    }
    return val.length <= max;
  }
  function tooLong(val, max) {
    return val.length > max;
  }
  $: allValid =
    valid(actual, maxActual, true) &&
    valid(expected, maxExpected, true) &&
    valid(name, maxName, false) &&
    valid(email, maxEmail, false) &&
    valid(subject, maxSubject, false);

  function remaining(val, max) {
    return max - val.length;
  }
  const maxActual = 2000;
  const maxExpected = 2000;
  const maxSubject = 80;
  const maxEmail = 80;
  const maxName = 80;
  const rows = 4;
  let hasSubmitted = false;
  let contactMe = false;
  let emailError = "";
  let typeError = "";
  const type="Technical website issue";
</script>

<Modal isOpen={true} fade={false}>
  <Form
    on:submit={(e) => {
      e.preventDefault();
      onSubmit();
      return false;
    }}
  >
    <ModalHeader toggle={() => onClose()}>Report a problem</ModalHeader>
    <ModalBody>

      <FormGroup>
       
       <FormText color="danger">Please only use this form for technical website issues. If your query relates to unsubscribing from mailings,
        changing donations, or any other request then please <a href="/{region}/contact/">contact out supporter relations team.</a></FormText>

    </FormGroup>


      <FormGroup>
        <Label for="Name"
          >Your name <span class="text-danger">*</span>
          <small class:text-danger={tooLong(name, maxName)}>
            Remaining characters: {remaining(name, maxName)}</small
          ></Label
        >
        <Input
          required
          style=""
          id="Name"
          type="text"
          maxlength={maxName}
          autocomplete="name"
          bind:value={name}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup>
        <Label for="Email">Your email <span class="text-danger">*</span></Label>
        <Input
          invalid={!!emailError}
          required
          style=""
          id="Email"
          type="email"
          autocomplete="email"
          maxlength={maxEmail}
          bind:value={email}
          disabled={isSubmitting}
        />
        {#if emailError}<span class="invalid-feedback" role="alert">{emailError}</span>{/if}
      </FormGroup>


      <FormGroup check class="mb-4">
        <Label check>
          <Input type="checkbox" bind:checked={contactMe} disabled={isSubmitting}/>
          I'm happy to be contacted regarding my request if necessary
        </Label>
       </FormGroup>

      <FormGroup>
        <Label for="Subject"
          >Subject <span class="text-danger">*</span>
          <small class:text-danger={tooLong(subject, maxSubject)}>
            Remaining characters: {remaining(subject, maxSubject)}</small
          ></Label
        >

        <Input
          required
          style=""
          id="Subject"
          type="text"
          autocomplete="off"
          maxlength={maxSubject}
          bind:value={subject}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup>
        <Label for="Expected"
          >What did you expect to happen?
          <small class:text-danger={tooLong(expected, maxExpected)}>
            Remaining characters: {remaining(expected, maxExpected)}</small
          >
        </Label>
        <Input
          style=""
          id="Expected"
          {rows}
          autocomplete="off"
          type="textarea"
          bind:value={expected}
          maxlength={maxExpected}
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup>
        <Label for="Actual"
          >What actually happened?
          <small class:text-danger={tooLong(actual, maxActual)}>
            Remaining characters: {remaining(actual, maxActual)}</small
          >
        </Label>
        <Input
          style=""
          id="Actual"
          {rows}
          autocomplete="off"
          type="textarea"
          bind:value={actual}
          maxlength={maxActual}
          disabled={isSubmitting}
        />
      </FormGroup>
    </ModalBody>
    <ModalFooter>
      <Button
        type="button"
        on:click={() => onClose()}
        disabled={isSubmitting}
        color="secondary">Close</Button
      >
      <Button type="submit" disabled={isSubmitting} color="primary">Send</Button
      >
      {#if errorMessage}
        <Alert color="danger">{errorMessage}</Alert>
      {/if}
    </ModalFooter>
  </Form>
</Modal>
