<script>
    import ContactModal from "./ContactModal.svelte";
    import { ModalFooter, Alert } from "sveltestrap";
    let open = false;

    const btns = [...document.querySelectorAll("button.bf-contact")];
    for (const btn of btns) {
        btn.addEventListener("click", () => {
            open = true;
        });
    }
    let success = false;
</script>

{#if open}
    <ContactModal
        onClose={() => {
            open = false;
            success = false;
        }}
        onSuccess={() => {
            open = false;
            success = true;
            setTimeout(() => (success = false), 1000);
        }}
    />
{/if}
{#if success}
    <div
        style="position: fixed; top: 10px; left: 0; right: 0;display: flex; justify-content: center;"
    >
        <Alert color="success">Feedback submitted successfully</Alert>
    </div>
{/if}
