

import ContactModalContainer from "./ContactModalContainer.svelte";

const modal = document.querySelector("div#bfContactModal")

if (modal){
    new ContactModalContainer({target: modal});
} 

 